$accent: #395f1c;
$accent-opacity: rgba(#d25a2c, 0.8);
$accent-light: #e66430;
$accent-light-opacity: rgba(#e66430, 0.8);
$black: #000;
$error: #de2329;
$white: #fff;
$ghost-white: #f8f8ff;
$white-smoke: #f5f5f5;
$gray: #aaa;
$gray-opacity: rgba(170, 170, 170, 0.521);
$gray-light: #eee;
$gray-light-medium: #cbcbcb;
$gray-dark-light: #8a8a8a;
$gray-dark: rgb(25, 25, 25);
$gray-medium: #303030;
$info-modal-bg: rgba(25, 25, 25, 0.5);
$title: #525252;

$color-collection: (
  'accent-opacity': (
    'light': $accent-opacity,
    'dark': $accent-opacity
  ),
  'accent': (
    'light': $accent,
    'dark': $accent
  ),
  'accent-bg': (
    'light': $accent,
    'dark': $black
  ),
  'accent-light': (
    'light': $accent-light,
    'dark': $accent-light
  ),
  'accent-light-opacity': (
    'light': $accent-light-opacity,
    'dark': $accent-light-opacity
  ),
  'black': (
    'light': $black,
    'dark': $black
  ),
  'black-bg': (
    'light': $black,
    'dark': $black
  ),
  'background': (
    'light': $gray-light,
    'dark': $gray-dark
  ),
  'error': (
    'light': $error,
    'dark': $error
  ),
  'foreground': (
    'light': $black,
    'dark': $white
  ),
  'gray': (
    'light': $gray,
    'dark': $gray
  ),
  'gray-light-medium': (
    'light': $gray-light-medium,
    'dark': $gray-light-medium
  ),
  'gray-bg': (
    'light': $gray,
    'dark': $white
  ),
  'gray-dark-light': (
    'light': $gray-dark-light,
    'dark': $gray-dark-light
  ),
  'gray-dark': (
    'light': $gray-dark,
    'dark': $gray-dark
  ),
  'gray-medium': (
    'light': $gray-medium,
    'dark': $gray-medium
  ),
  'gray-light': (
    'light': $gray-light,
    'dark': $gray-light
  ),
  'gray-light-bg': (
    'light': $gray-light,
    'dark': $gray-dark
  ),
  'info-modal-bg': (
    'light': $info-modal-bg,
    'dark': $info-modal-bg
  ),
  'shadow': (
    'light': #aaa,
    'dark': #333
  ),
  'shadow-dark': (
    'light': #888,
    'dark': #6d6d6d
  ),
  'gray-opacity': (
    'light': $gray-opacity,
    'dark': $gray-opacity
  ),
  'white': (
    'light': $white,
    'dark': $white
  ),
  'white-smoke': (
    'light': $white-smoke,
    'dark': $white-smoke
  ),
  'ghost-white': (
    'light': $ghost-white,
    'dark': $ghost-white
  ),
  'white-bg': (
    'light': $white,
    'dark': $gray-dark
  ),
  'title': (
    'light': $title,
    'dark': $title
  )
);

@function get-color($theme, $color) {
  $map: map-get($color-collection, $color);
  @return map-get($map, $theme);
}
