@import '../variables/breakpoints';

@mixin app-container($theme) {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  position: relative;
  background-color: get-color($theme, 'background');

  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: get-color($theme, 'foreground');
  }

  .app-drawer-container {
    display: flex;
    flex: 1;
    z-index: 0;
    @include small-screen {
      display: none;
    }
  }

  .app-sub-container {
    // display: flex;
    // flex: 1;
    z-index: 0;
    padding-left: $sidebar-min-width;
    // margin-top: 20px;
    height: 100%;
    // width: 100%;
    background-color: get-color($theme, 'background');
    @include small-screen {
      padding-left: 0;
    }
  }

  .app-content {
    // margin-top: 20px;
    display: flex;
    flex: 1;
    padding-top: 45px;
    background-color: get-color($theme, 'background');
  }
}

.app-container {
  &-light {
    @include app-container('light');
  }
  &-dark {
    @include app-container('dark');
  }
}
